//$(document).foundation();

$(function(){

    $('#main-nav > button').click(function(){
        $(this).parent('#main-nav').toggleClass('active');
    });

});


// ====================================== Height Calculation ======================================== //

$(function () { 

    var $window = $(window);  
    var scrollTime = 1.2;   
    var navItem = $('#main-nav');
    var durationValue;
    var currentSection = -1;

    var controller = new ScrollMagic.Controller();

    var activateItem = function(index) {
        navItem.find('li').removeClass('active');
        navItem.find('li').eq(index).addClass('active');
    }

    var decorHeight = function(value) {
      navItem.find('.road').css('height', value*100+'%');
    }

    $('.section').each(function(){
        var index = $('.section').index(this);
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 1,
            duration: "200%"
        })
        .setClassToggle($('#bg'),'bg'+index)
        //.addIndicators()
        .addTo(controller)
        /*.on("enter leave", function (event) {
          if(event.state == 'DURING') {
            console.log("Current scene is " + index);
            activateItem(index);
          }
        })*/
        ;
    });

    $('.section').each(function(){
        var index = $('.section').index(this);
        var tween = new TimelineMax();


        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0
        })
        //.addIndicators()
        .addTo(controller)
        .on("enter leave", function (event) {
            event.state == 'DURING' ? currentSection++ : currentSection--;
            activateItem(currentSection);
        })
        ;
    });

    $('.section:not(#intro-section) h1').each(function(){

        var text = $(this).text();
        $(this).html('');
        var tl = new TimelineMax();
        tl.to($(this), 0.5, {scrambleText:{text: text, chars:"укенгшзхывапролджэячсмитьбю", revealDelay:0.2, tweenLength:false, ease:Linear.easeNone}});
        
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.6
        })
        .setTween(tl)
        //.addIndicators()
        .addTo(controller)
        ;
    });
    
    var containerScene = new ScrollMagic.Scene({
        triggerElement: '.container-wrap',
        triggerHook: 0
    })
    //.addIndicators()
    .addTo(controller)
    .on("progress", function (event) {
        decorHeight(event.progress);
    })
    ;

    var heightCalc = function() {
      var elemHeights = [];
      var windowHeight = window.innerHeight;
      var contentHeight = $('.container-wrap').height();
      durationValue = contentHeight - windowHeight;
      $('.section-image').height(windowHeight);
      $('.section-header').height(windowHeight);
      $('.section-content').css('min-height',windowHeight);



      /*if($('.section').length) {

        $('.section').each(function() {
          elemHeights.push($(this).height());
        });
        
        navItem.find('li').each(function(key){
          var elemHeight = elemHeights[key]/contentHeight*100+'%';
          $(this).css('height', elemHeight);
        });
        
      }*/
    }

    $(window).on("resize", heightCalc);
    $(window).triggerHandler("resize"); 
    //containerScene.duration(durationValue); 

});

$(function(){
  $('#main-nav a').click(function (event) {
    var linkRef=$(this).attr('href');

    if(linkRef) {

      $('body, html').animate({
        scrollTop: $(linkRef).offset().top+$('#container')[0].scrollTop
      }, 500);

      event.preventDefault();
    }

  });
});

